/**
 * Gatsby Browser APIs
 * 
 * ビルド中および実行時に Gatsby がブラウザで動作する方法をカスタマイズできます
 */

const React = require("react")
const Layout = require("./src/components/layout").default

// すべてのページでレイアウトコンポーネントを使用
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

// ページが変更されたときのブラウザキャッシュをクリア
// これにより新しいコンテンツが確実に表示される
exports.onRouteUpdate = () => {
  if (process.env.NODE_ENV !== 'development') {
    // プロダクション環境でのみ実行
    if (typeof window !== 'undefined') {
      // SSRでエラーを起こさないためのチェック
      window.GATSBY_ROUTE_UPDATED = new Date().getTime();
    }
  }
}